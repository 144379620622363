import { Component } from '@angular/core';

import { NbAuthJWTToken, NbAuthService, NbTokenStorage, NbAuthToken } from '@nebular/auth';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
     <span class="created-by" style="display:block;">



      <div style="float:left;">MIRA - Műtő Irányító Alkalmazás</div>
      <div style="float:left;margin-left:30mm;">Felhasználó: {{user?.name}}[{{user?.id}}]</div>
      <div style="float:right; margin-left:15mm;">Nyomtatás időpontja: {{datum | date: 'yyyy.MM.dd.  HH:mm:ss'}}</div>
    </span>
    
  `,
})
export class FooterComponent {


  datum=new Date();
  user: any;
  token:any;


  
  constructor( private authService: NbAuthService) {
    setInterval(() => {
     this.datum= new Date();
    }, 1000);



 //user adatok kiszedése
 this.authService.onTokenChange()
 .subscribe((token: NbAuthJWTToken) => {

   if (token.isValid()) {
     this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
     this.token=token.getValue();
   }

 });


    }
}
