import { Component,Injectable, ViewChild, Input, OnDestroy, OnInit , NgModule, Pipe, PipeTransform, TemplateRef, ViewEncapsulation, ViewContainerRef, AfterViewInit } from '@angular/core';


import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NbDialogService } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import {TranslateService} from '@ngx-translate/core'; //i18n-hez
import {TranslateModule} from '@ngx-translate/core'; //i18n-hez
import{ GlobalConstants } from '../../global-constants'; // api url stb

/*
import * as htmlToImage from 'html-to-image';
import { toJpeg } from 'html-to-image';*/


import {
    NbComponentStatus,
    NbGlobalLogicalPosition,
    NbGlobalPhysicalPosition,
    NbGlobalPosition,
    NbToastrService,
  } from '@nebular/theme'; //toasterhez kell


/*
  import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';*/

import {MiraAPI_hiba} from './template/hiba.component';

//import {MiraAPI_mentetlen_megerosit} from './template/mentetlen_megerosit.component';


///////////////////////////////////////////////////
//ngmodule-hoz
/*import { ThemeModule } from '../../@theme/theme.module';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbTabsetModule,
  NbUserModule,
  NbRadioModule,
  NbSelectModule,
  NbListModule,
  NbIconModule,
NbCheckboxModule,
NbInputModule,
NbPopoverModule,

} from '@nebular/theme';*/
///////////////////////////////////////////////////////////

@Component({
    selector: 'MiraAPI',
    templateUrl: './api.html',
    styleUrls: ['../../globalstilus.scss'],
    //encapsulation: ViewEncapsulation.None,
})



@Injectable({
    providedIn: 'root'
  })



/*
  @NgModule({
    imports: [
      ThemeModule,
      CommonModule,
      NbCardModule,
      NbButtonModule,
      NbSelectModule,
      NbCheckboxModule,
      NbInputModule,
      NbButtonModule,
      TranslateModule,
      NbPopoverModule,
    

      
      

    ],
    declarations: [

    ],
    providers: [
      
      
      
      
    ],
  })
*/
/*
@NgModule({
  imports: [
    // ...
    NbCardModule,
  ],
})*/





export class MiraAPI implements AfterViewInit {


  private httpHeaders = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
    })
};

    apiURL = GlobalConstants.apiURL; //ideiglenesen, majd meg kell szüntetni ezt a GlobalConstants.apiURL-t
    private user:any;
    private token:any;

    //@ViewChild('HibaTemplate', {static: false}) HibaTemplateRef!: TemplateRef<any>;
    //@ViewChild('HibaTemplate') private content: TemplateRef<any>;
    @ViewChild('HibaTemplate', { static: true }) name!: TemplateRef<any>;
    //@ViewChild('HIBA') hibadialog: TemplateRef<any>;

constructor (
    private authService: NbAuthService,
    private dialogService: NbDialogService,
    private translate: TranslateService,
    private httpClient: HttpClient,
    private toastrService: NbToastrService,
    private vref:ViewContainerRef,
) {






    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.token=token.getValue();
      }
    });

}


ngAfterViewInit() {
  //console.log(this.content);
  //this.vref.createEmbeddedView(this.content);
 
}
teszt()
{
  return this.vref.createEmbeddedView(this.name);
}
/**
 * Send a POST request
 * @param url
 * @param data
 * @returns {Promise<Object>}
 */


    async Lekerdez(url, data)
    {
        var data_tokennel = Object.assign(data, {token:this.token});
        //this.tokenfrissit(); //a végére kerül inkább

      try{

        
        
        return this.httpClient.post(this.apiURL+url, data_tokennel, this.httpHeaders).pipe(
          tap({
              error: error => {
                  if (error.status === 500) {
                      // Handle 500
                      this.tokenfrissit();
                      this.Hibauzenet_ablak(error, data_tokennel);
                      return false;
                  } else if (error.status === 400) {
                      // Handle 400
                      this.tokenfrissit();
                      this.Hibauzenet_ablak(error, data_tokennel);
                      return false;
                  } else if (error.status === 401) {
                      // Handle 401
                      this.tokenfrissit();
                      this.Hibauzenet_ablak(error, data_tokennel);
                      return false;
                  }
                    else if (error.status === 404) {
                      // Handle 404
                      
                      return false;
                  }
                  else 
                  {
                    this.tokenfrissit();
                    this.Hibauzenet_ablak(error, data_tokennel);
                    return false;
                  }
              }}),
              catchError(err => {
                throw 'Hiba az API kommunikáció során: ' + err;
                //console.log(err);
              })
              ).toPromise().catch(error => {
                 console.error(error)
              }).finally(() => {
                // code to cleanup
                this.tokenfrissit();
              });;




              




















      }
      catch(error)
      {
        console.log('Hiba a lekerdezes soran:');
        console.log(error);
      }
      //this.tokenfrissit();
            
    }


    async tokenfrissit(ujtoken='')
    {
      if(ujtoken=='')
      this.authService.refreshToken('email', this.token).subscribe(); 
      else
      this.authService.refreshToken('email', ujtoken).subscribe(); 
    }

    tokenlekerdezes()
    {
      return this.token;
    }

    userlekerdezes()
    {
      return this.user;
    }



                        //success, warning, danger, info, primary...
    public uzenet_mutat(type: NbComponentStatus, title: string, body: string, masodperc:number= 2) {
      const config = {
        status: type,
        destroyByClick: true,
        duration: masodperc*1000,
        hasIcon: true,
        position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
        preventDuplicates: false,
      };
      const titleContent = title ? `${title}` : '';
    
      //this.index += 1;
      this.toastrService.show(
        body,
        `${titleContent}`,
        config);
    }



    
    
    Hibauzenet_ablak(hibauzenet_reszletek='', kuldott_adat:any)
    {
      let valami=
        this.dialogService.open(MiraAPI_hiba, {
          hasBackdrop: true,
          backdropClass:'hatter-elmos',
          closeOnBackdropClick: false,
          hasScroll:false,
          autoFocus:false,
          closeOnEsc:false,
          //viewContainerRef:MiraAPI_hiba,
            context: {
              cimsor: 'Kritikus hiba!',
              ref:null,
              hibauzenet:'Kritikus hiba történt a szerverrel való kommunikáció során',
              hibauzenet_reszletek:hibauzenet_reszletek,
              kepernyokep:'',
              kuldott_adat:kuldott_adat,
            },
          });
          valami.componentRef.setInput('ref',valami);
    }



    async Mentetlen_adat_megerosit3()
    {
      /*let valami=
        this.dialogService.open(MiraAPI_mentetlen_megerosit, {
          hasBackdrop: true,
          backdropClass:'hatter-elmos',
          closeOnBackdropClick: false,
          hasScroll:false,
          autoFocus:false,
          closeOnEsc:false,
          //viewContainerRef:MiraAPI_hiba,
            context: {
              ref:null,
            },
          });
          valami.componentRef.setInput('ref',valami);
          console.log(valami);

          while(valami.componentRef.instance['dialog_folyamatban'])
          {

          }
          return valami.componentRef.instance['eredmeny'];*/
          var eredmeny;
          eredmeny= await this.Mentetlen_adat_megerosit3();
          /*setTimeout(() => {
            eredmeny=  this.Mentetlen_adat_megerosit2();
          }, 100);*/
          

          return Boolean(eredmeny);
    }

/*
    async Mentetlen_adat_megerosit()
    {
      //let folyamatban= true;
      //let eredmeny = false;
      let valami=
        this.dialogService.open(MiraAPI_mentetlen_megerosit, {
          hasBackdrop: true,
          backdropClass:'hatter-elmos',
          closeOnBackdropClick: false,
          hasScroll:false,
          autoFocus:false,
          closeOnEsc:false,
          //viewContainerRef:MiraAPI_hiba,
            context: {
              ref:null,
            },
          })

          valami.componentRef.setInput('ref',valami);
          //let valami2 = await valami.toPromise();
          //valami.onClose.subscribe(eredmeny => {
          //  console.log("eredmény:"+eredmeny);
          //  return eredmeny;
          //}
          //);
          
          
          //valami.componentRef.setInput('ref',valami);
          
          
          
          //valami.componentRef.setInput('folyamatban',folyamatban);
          //valami.componentRef.setInput('eredmeny',eredmeny);
          console.log(valami);

          //while(folyamatban)
          //{

          //}
          return false;


          

    }*/


  async teszt2(valami)
  {
    

    while(valami.componentRef.instance['dialog_folyamatban'])
      {
        
      }
      return valami.componentRef.instance['eredmeny'];
  }



    Mentes(url, data)
    {

    }






    public formatDate(date, csak_ora_perc=false) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear(),
          hour = '' + d.getHours(),
          minute= '' + d.getMinutes()
    
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
      if(hour.length < 2)
          hour = '0' + hour;
      if(minute.length < 2)
          minute = '0' + minute;

      if(csak_ora_perc) if(!Number.isNaN(d.getHours())) return hour+':'+minute+':00'; else return null;
      return [year, month, day].join('-');
    }
    
    public datumma_alakit(datum)
    {
      return new Date(datum);
    }

    /** adott dátumhoz megadott percet hozzáad, majd az új dátumot visszaadja */
    public perc_hozzaad(datum, perc) {
      return new Date(datum.getTime() + perc*60000);
    }

    /** két dátum közötti különbség percben (első a nagyobb) */
    public perc_kulonbseg(datum1, datum2) {
      var diff = Math.abs(datum1 - datum2);
      var minutes = Math.floor((diff/1000)/60);
      return minutes;
    }



    







}
