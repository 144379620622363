//ez egyelőre egy teszt...



import { Injectable } from '@angular/core';

import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class TokenService {
  constructor(private authService: NbAuthService,
              private router: Router
              
              ) 
    {

      //user adatok kiszedése
     this.authService.onTokenChange()
     .subscribe((token_temp: NbAuthJWTToken) => {
 
       if (token_temp.isValid()) {
        //console.log("valtozott a token");
         this.useradatok = token_temp.getPayload(); 
         this.tokenadatok=token_temp.getValue();
         if(this.useradatok['exp']=='2' && this.router.url!="/") //invalid a token, kiléptetjük
         {
          localStorage.removeItem("auth_app_token");
          //console.log("token invalid, töröljük");
          this.router.navigate(['auth/login']);
         }
         
       }
       else
       {
        if(this.router.url!="/")
        {
          //console.log("token nem valid, "+this.router.url);
        localStorage.removeItem("auth_app_token");
        this.router.navigate(['auth/login']);
        }
        
       }
 
     });
      //this.Tokenadatok_lekerese()
      
    }

    private useradatok:any;
    private tokenadatok:any;
  
  Tokenadatok_lekerese()
  {
     

     //this.authService.refreshToken('email', this.tokenadatok).subscribe(); 
  }



  User_adatok(){
    this.Tokenadatok_lekerese();
    return this.useradatok;
  }
  Token_tartalma(){
    this.Tokenadatok_lekerese();
    return this.tokenadatok;

  }
}