import { Component } from '@angular/core';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout>
    <nb-layout-footer fixed class="csak-nyomtat">
        <ngx-footer style="display:block !important;"></ngx-footer>
      </nb-layout-footer>
      <nb-layout-header fixed class="nem-nyomtat" id="navbar">
        <ngx-header id="navbar_header"></ngx-header>
      </nb-layout-header>

      <!--<nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>-->

      <nb-layout-column class="hatter-container" id="hatter-container" style="z-index:10;">
        <ng-content select="router-outlet" class="hatter-kep2"></ng-content>

        <div class="nyomtat_logo">&nbsp;</div>

        <div class="nem-nyomtat" style="opacity: 0.4;
        background-image: url(assets/images/logo01_kek.png);
        z-index: -1;
        position: fixed;
        right: 30px;
        bottom: 30px;
        width: 632px;
        height: 214px;"></div>

      </nb-layout-column>

     
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {}
