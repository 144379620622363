import { EditorComponent } from '@tinymce/tinymce-angular';
//import {MiraAPI} from './egyedi_komponensek/api/api.component'; 

export class GlobalConstants {
    public static apiURL: string = "api/";
    //public static verzioszam : string = "0.8.3";
    public static verzioszam : string = "b0.5.1";

    //public static apiURL: string = "https://api.mira.hnetwork.no-ip.org/api/";
    public static lapteteje()
    {
     //TODO: safari alatt nem működik...
     window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
     
    } 



    static tinymce_szin='default';
    public static tinymce_init= EditorComponent['init'] = {
      /* ... */
      base_url: '/tinymce', // Root for resources
      suffix: '.min',        // Suffix to use when loading resources
      promotion: false,
      branding:false,
      content_css: this.tinymce_szin,
      menubar:false,
      resize: false,
      plugins: 'table image link lists emoticons',
      toolbar: [
          'undo redo | fontsize bold italic underline | alignnone aligncenter alignleft alignright alignjustify | backcolor forecolor | image | link unlink | bullist numlist | emoticons',
          'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |',
    
    ],
      
      language:'hu_HU'
    };

}