import { Component,Injectable, ViewChild, Input, OnDestroy, OnInit , NgModule, Pipe, PipeTransform, TemplateRef, ViewEncapsulation, AfterViewInit } from '@angular/core';


import * as htmlToImage from 'html-to-image';
import { toJpeg } from 'html-to-image';

import { MiraAPI } from "../api.component";


import { ViewContainerRef } from '@angular/core';

@Component({
    selector: 'MiraAPI_hiba',
    templateUrl: './hiba.html',
    styleUrls: ['../../../globalstilus.scss'],
    //encapsulation: ViewEncapsulation.None,
})




export class MiraAPI_hiba implements AfterViewInit {

    cimsor: string; hibauzenet: string; hibauzenet_reszletek:any; kuldott_adat:string;
    hiba_felhasznalo_reszlet="";
    loading=false;
    //public kepernyokep='';

    public kepernyokep_folyamatban=0;

    @Input() public  ref: any;
    @Input() public  kepernyokep: any;


    ngAfterViewInit()
    {

    }


constructor (
    //private viewContainerRef: ViewContainerRef,
    //private dialogService: NbDialogService,
    private miraAPI: MiraAPI,
) {




    

//this.kepernyokep_keszit();

}


async hibajelentes_kuld()
{
     await this.kepernyokep_keszit();
    /*setTimeout (() => {
        this.ref.close();
     }, 1500);*/
     this.miraAPI.Lekerdez("hibajelentes",{
        hibauzenet: this.hibauzenet, 
        hibauzenet_reszletek:this.hibauzenet_reszletek, 
        kuldott_adat:this.kuldott_adat, 
        hiba_felhasznalo_reszlet:this.hiba_felhasznalo_reszlet,
        kepernyokep:this.kepernyokep
    });
     this.ref.close();
}



    async kepernyokep_keszit()
    {
        this.kepernyokep_folyamatban=1;
        this.loading=true;
        let valami:any;

     await htmlToImage.toJpeg(document.getElementById('hatter-container'), { quality: 0.90, })
      .then(function (dataUrl) {

        valami=dataUrl;
      });
      this.kepernyokep=valami;
      this.kepernyokep_folyamatban=0;
      this.loading=false;
    }



    






    








}

