export class GlobalConstants {
    public static apiURL: string = "api/";
    //public static verzioszam : string = "0.8.3";
    public static verzioszam : string = "DEV";

    //public static apiURL: string = "https://api.mira.hnetwork.no-ip.org/api/";
    public static lapteteje()
    {
     //TODO: safari alatt nem működik...
     window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
     
    } 

}