import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Router } from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import{ GlobalConstants } from '../../../global-constants'; // api url stb
import { MiraAPI } from "../../../egyedi_komponensek/api/api.component";


import { NbAuthJWTToken, NbAuthService, NbTokenStorage, NbAuthToken } from '@nebular/auth';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  
})
export class HeaderComponent implements OnInit, OnDestroy {



  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  verzioszam = GlobalConstants.verzioszam;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
    {
      value: 'MIRA_LIGHT',
      name: 'MIRA világos',
    },
  ];

  currentTheme = 'default';

  token:any;




  notes=[{"id":"id1", "text":"text1"}, {"id":"id2", "text":"text2"}];


















  

  userMenu = [ { title: 'Profil' }, { title: 'Kijelentkezés' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private router: Router,
              private authService: NbAuthService,
              private tokenstorage: NbTokenStorage, 
              private httpClient: HttpClient,
              public miraAPI: MiraAPI,
              public translate: TranslateService,) {



                //user adatok kiszedése
                this.authService.onTokenChange()
                .subscribe((token: NbAuthJWTToken) => {
          
                  if (token.isValid()) {
                    this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
                    this.token=token.getValue();
                  }
          
                });


                                  //kijelentkezés
                                  this.menuService.onItemClick().subscribe((event) => {
                                    if (event.item.title === 'Kijelentkezés') {
                                      this.logout();
                                    }
                                    if (event.item.title === 'Profil') {
                                      this.navigate("/oldal/beallitasok/profil");
                                    }
                                  });
                
                                  if(this.user.tema!=null)
                                  this.changeThemeOnstartup(this.user.tema); //témaváltás a bejelentkezett felhasználónak megfelelően



this.setTransLanguage();


var prevScrollpos = window.pageYOffset;
var scrollpixelszam=50;
var elozovaltas=0;
window.onscroll = function() {
  var width = window.innerWidth;
  var currentScrollPos = window.pageYOffset;
  if(prevScrollpos<currentScrollPos) elozovaltas= currentScrollPos;
  if(width<992)
  if (prevScrollpos > currentScrollPos) {
    
    /*const navbar = document.getElementById("navbar");
    const navbar_elem = navbar.getElementsByTagName("nav"); 
    //document.getElementById("navbar").style.top = "0";
    //navbar_elem[0].classList.remove("navbar-fixed-top");
    //navbar_elem[0].classList.add("fixed");
    
    navbar_elem[0].style.top="0";
    navbar_elem[0].style.position="static";*/

    if(elozovaltas==0 || (currentScrollPos-elozovaltas>50 || elozovaltas-currentScrollPos>50 || currentScrollPos<10))
    {
      elozovaltas=currentScrollPos;
      document.getElementById("navbar_header").classList.remove("navbar-fixed-top");
      document.getElementById("navbar2").classList.remove("navbar-fixed-top");
    }
    
  } else {
    //if (currentScrollPos>80)
    //document.getElementById("navbar").style.top = "-80px";
    /*const navbar = document.getElementById("navbar");
    const navbar_elem = navbar.getElementsByTagName("nav"); 
    //navbar_elem[0].classList.add("navbar-fixed-top");
    //navbar_elem[0].classList.remove("fixed");

    navbar_elem[0].style.top="-80px";
    navbar_elem[0].style.position="relative";*/
    

      document.getElementById("navbar_header").classList.add("navbar-fixed-top");
      document.getElementById("navbar2").classList.add("navbar-fixed-top");
    

    
    
  }
  prevScrollpos = currentScrollPos;
}
  }









  

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    /*this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);*/

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }


  changeThemeOnstartup(themeName: string) {
    this.themeService.changeTheme(themeName);
  }


  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
    
    var data={token:this.token, themename:themeName};
    //this.httpClient.post('./api/changetheme.php',data).subscribe(data => {},error => console.error(error)); //téma változás írása az adatbázisba
    //this.httpClient.get('./api/auth/refreshtoken.php?themename='+themeName+"&token="+this.token).subscribe(data => { this.authService.refreshToken('email', data['token']).subscribe(); },error => console.error(error)); //téma változás írása a tokenbe
    
  }

  async OsztalyValtas(osztalyid:any) {
    //var osztalyid=0;
    var data={token:this.token, osztalyid:osztalyid};
    console.log("Osztály váltás:"+osztalyid);
    var ujtoken= await this.httpClient.post(GlobalConstants.apiURL+'auth/refreshtoken/osztaly/'+osztalyid+'',data).toPromise();
    var token_uj={createdAt:Date.now(), name:'nb:auth:jwt:token', ownerStrategyName:'email', value:ujtoken['token']};
    localStorage.setItem('auth_app_token',JSON.stringify(token_uj));
    //this.router.
    window.location.reload();
  }


  logout() {
    localStorage.removeItem("auth_app_token");
    var data={felhasznalonev:this.user.username};
    this.httpClient.post(GlobalConstants.apiURL+'auth/logout',data).toPromise(); //hogy tudassuk és logolhassuk a kijelentkezést
    this.router.navigate(['auth/login']);
}

setTransLanguage(){
  if (typeof this.user !== 'undefined') {
    this.translate.use(this.user['nyelv']);
  }
  
  }



navigate(hova)
{
  this.router.navigate([hova]);
  return false;
}









}
